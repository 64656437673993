<template>
  <item-card>
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
          Pozīcijas
      </h3>
    </template>

    <template v-slot:content>
      

      <!--  List all order items  -->
      <template v-if="invoice.order_items" >
        <div class="divide-y divide-gray-200 dark:divide-gray-700 w-full">
          <SingleInvoiceOrderItem v-for="(item, index) in invoice.order_items" :key="index" :item="item" :order="invoice" />
        </div>
      </template>
            
    </template>
  </item-card>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import SingleInvoiceOrderItem from "@/components/Invoices/Invoice/SingleInvoiceOrderItem"

export default {
  name: "AllInvoiceOrderItems",
  props: ['invoice'],
  components: {
    ItemCard,
    SingleInvoiceOrderItem,
  },
}
</script>
