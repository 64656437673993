<template>
  <li>
    <item-card>
      <template v-slot:title>
        <h3 class="text-lg font-medium text-gray-900 dark:text-gray-300">
          {{item.description}}
        </h3>
      </template>

      <template v-slot:buttons>
          <template v-if="item.unique_items && item.unique_items.length">
              <Button :icon="showUniqueCodes ? 'chevron-up' : 'chevron-down'" @click="showUniqueCodes = !showUniqueCodes" tooltip="Skatīt kodus" />
          </template>
      </template>

      <template v-slot:content>

          <item-text title="Daudzums" :text="item.quantity" />
          <item-text title="Pārdošanas cena" :text="item.price" />
          <item-text title="Iepirkuma cena" :text="item.purchase_price" />
          <item-text title="Starpība" :text="item.difference" />

          <item-text title="Kopā" :text="item.total_with_discount" textClass="font-bold" />
          <item-text title="Iepirkums kopā" :text="item.purchase_total" textClass="font-bold" />
          
          <item-text title="Kopā starpība" :text="item.total_difference" textClass="font-bold"  />

      </template>

      <template v-slot:additional>
        <template v-if="item.unique_items && item.unique_items.length && showUniqueCodes">
          <div class="bg-gray-200 dark:bg-gray-800 px-3 py-2 rounded-lg">
            <template v-for="unit in item.unique_items" :key="unit.id">


                <div class="flex w-full py-1">
                    <div class="w-full grid grid-cols-12 px-2 rounded-lg">
                        <div class="col-span-12 sm:col-span-6 lg:col-span-4">
                            <ItemText title="Piegādātājs">
                                <template v-slot:content>
                                    <router-link :to="`/stock/procurements/${unit.procurement_item.procurement_id}`" target="_blank" class="mt-2 flex items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0 hover:text-main1 dark:hover:text-main1">
                                        {{unit.procurement_item.procurement.supplier.name}}
                                        <span class="text-sm pl-1">
                                         ({{unit.procurement_item.procurement.supplier_uuid}})
                                        </span>
                                    </router-link>
                                </template>
                            </ItemText>
                        </div>
                        
                        <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                            <ItemText title="Cena" :text="unit.procurement_item.price" />
                        </div>

                        <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                            <ItemText title="Kods">
                                <template v-slot:content>
                                    <div class="flex items-center">
                                        <router-link :to="`/catalog_item_units/${unit.number}`" target="_blank" class="mt-2 flex items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0 hover:text-main1 dark:hover:text-main1 mr-1">
                                            {{unit.number}}
                                        </router-link>

                                        <Button icon="copy" tooltip="Kopēt kodu" @click="copyText(unit.number)" className="px-0.5 py-0.5 -mt-1" />
                                    </div>
                                </template>
                            </ItemText>
                        </div>
                    </div>
                </div>
            </template>
          </div>
        </template>
      </template>
    </item-card>
  </li>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"

export default {
  name: "SingleInvoiceOrderItem",
  components : {
    ItemCard,
    ItemText,
  },
  props: ['order','item', 'itemsLowOnStock'],
  data: () => ({
    showUniqueCodes: false,
  }),
  methods: {
    copyText(text) {
        var dummy = document.createElement("textarea");
        // to avoid breaking orgain page when copying more words
        // cant copy when adding below this code
        // dummy.style.display = 'none'
        document.body.appendChild(dummy);
        //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
        dummy.value = text;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
    }
  }
}
</script>

<style>
</style>